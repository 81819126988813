import React from "react"
import { graphql } from "gatsby"
import PageLayout from "../components/page-layout"
import "../fonts/stylesheet.css"
import "../styles/global.css"


const PagePage = ({ data }) => {
  const page = data.strapiPage

  const seo = {
    metaTitle: page.title,
    metaDescription: page.description,
    shareImage: page.cover,
  }

  return <PageLayout seo={seo} page={page}></PageLayout>
}

export const pageQuery = graphql`
  query ($url: String) {
    strapiPage(url: { eq: $url }) {
      id
      url
      title
      description
      blocks {
        ...Blocks
      }
      cover {
        alternativeText
        localFile {
          url
          childImageSharp {
            gatsbyImageData(
              width: 890
            )
          }
        }
      }
    }
  }
`

export default PagePage
